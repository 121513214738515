import React from "react";
import { getLocalization } from "../../translations"
import './styles.css'

function MainContent({language}) {

    return (
        <section className="main-content">
            <div className="headingArea">
                <h3 className="headingArea-heading" id="AboutUs">ABOUT US</h3>
                <h4 className="headingArea-subHeading">{getLocalization(language, "text_subheading")}</h4>
                <p className="headingArea-background">ABOUT US</p>
                </div>
            <div className="MainArea">
                <p className="motto">{getLocalization(language, "motto1")}<br/>{getLocalization(language, "motto2")} <br/>{getLocalization(language, "motto3")}</p>
            
            <p className="main-content--description">{getLocalization(language, "text_description")}</p>
            </div>
        </section>
    )
}

export { MainContent }