import React from "react";
import { getLocalization } from "../../translations"
import './styles.css'

function PCHomeBanner({language}) {

    return (
        <div className="banner-pc">
                <div className="container-pc">
                    <div className="bannerColContainer-pc">

                        <div className="bannerCols-pc">
                            <div className="titleArea-pc">
                                <h1>About Us</h1>
                                <h4>{getLocalization(language, "text_subheading")}</h4>
                            </div>
                            <h2>{getLocalization(language, "motto1")}<br/>{getLocalization(language, "motto2")}{getLocalization(language, "motto3")}<br/>{getLocalization(language, "motto4")}<br/>{getLocalization(language, "motto5")}</h2>
                        </div>
                        <div className="bannerCols-pc">
                            <div className="bannerImg-pc">
                                <img src="/home/qburst-product-banner-img.png" alt="" srcSet="" />
                            </div>
                        </div>

                    </div>
                    <p className="bannerDesc-pc">{getLocalization(language, "text_description")}</p>
                </div>
            </div>
        )
}

export { PCHomeBanner }