import {React, useState} from "react";
import './styles.css';
import { AccordionMenu } from "../HamburgerAccordion";
import { getLocalization } from "../../translations"
import joinClasses from "../../utils/joinClasses";
import { useNavigate } from "react-router-dom";

function Header({language, setLanguage}){
    const navigate= useNavigate();
    const [isOpen, setOpen] = useState(false)
    const toggleMenu = () => {
      const body = document.getElementsByTagName("body")[0]
      body.style.overflow =  `${!isOpen ? "hidden" : "auto"}`

      setOpen(!isOpen);
      };

    

    function handleClick(){
        document.getElementsByTagName("html")[0].lang = document.getElementsByTagName("html")[0].lang === "jp" ? "en" : "jp"
        //Logic to toggle the language used in the page
        setLanguage(language === "jp" ? "en" : "jp")
    }
    
    const jumpAboutCompany = () => {
      window.location.replace('https://www.qburst.com/jp-jp/company/profile?utm_source=website&utm_medium=productcatalog&utm_campaign=japanitweek2023');
    };

    return(
      <>
        <header className="header">
            <a href="/" className="header--qburst-logo-icon">
                <img src="/Logos/QBurst_Logo.svg" alt="QBurst" />
            </a>
            
              {/* hamburger icon */}
              <div role="button" onClick={toggleMenu} className={joinClasses("hamburgerIcon", isOpen && "open")}>
                <span></span>
                <span></span>
                <span></span>
              </div>
        </header>
        <div className="headerPlaceholder" />
        {/* menu */}
        <div className={`menu-drawer ${isOpen ? 'open' : ''}`}>
          <p className="menu-title1" onClick={() => {
                  
                  toggleMenu()  
                  navigate({ pathname: "/" })
                  window.scrollTo(0, 0)
                  }}
          >About Us</p>
          <p className="menu-subtitle1">私たちについて</p>
          <AccordionMenu>
            <ul>
            <li>
                <p className="content" onClick={()=>{
                  toggleMenu()
                  navigate({ pathname: "/", search: "?product=cross-channel" })}}>
                  Cross Channel
                  <span className="description">{getLocalization(language, "cross_channel_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=evalgator" })
                  }}>
                  Evalgator
                  <span className="description">{getLocalization(language, "evalgator_product_description")}</span>
                </p>
              </li>

              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=affiliate" })}}>
                  PartnerFront
                  <span className="description">{getLocalization(language, "affiliate_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=finbooks" })}}>
                  Finbooks
                  <span className="description">{getLocalization(language, "finbooks_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=slashq" })}}>
                  SlashQ
                  <span className="description">{getLocalization(language, "slashq_product_description")}</span>
                </p>
              </li>

              <li>
                <p className="content" onClick={() => {
                  toggleMenu()  
                  navigate({ pathname: "/", search: "?product=seeMyMachine" })
                  }}>
                  See My Machine
                  <span className="description">{getLocalization(language, "seemymachine_product_description")}</span>
                </p>
              </li>
             
              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=enlite" })}}>
                  Enlite
                  <span className="description">{getLocalization(language, "enlite_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content" onClick={()=>{
                  toggleMenu() 
                  navigate({ pathname: "/", search: "?product=rehash" })}} >
                  Rehash 
                  <span className="description">{getLocalization(language, "rehash_product_description")}</span>
                </p>
              </li>
              
             
              
              
            </ul>
          </AccordionMenu>
          <div className="aboutCompany" onClick={jumpAboutCompany}>
          <p className="menu-title3">Company</p>
          <p className="menu-subtitle3" >会社概要</p>
          </div>
          <button className="header--language" onClick={handleClick}>{language === "jp" ? "English" : "日本語"}</button>

        </div>
      </>
    )
}

export { Header }
