import React from "react"
import './styles.css'
import Affconfig from "./Affiliate.json"
import Crossconfig from "./CrossChannel.json"
import Enliteconfig from "./Enlite.json"
import Evalgatorconfig from "./Evalgator.json"
import Finbooksconfig from "./Finbooks.json"
import Gauzesconfig from "../Gauzes.json"
import Rehashconfig from "./Rehash.json"
import SlashQconfig from "./SlashQ.json"


function PCProductPage ({product, language}) {
    let config

    if(product === "Crosschannel") {
        config = Crossconfig
    } else if (product === "Affiliate") {
        config = Affconfig
    } else if (product === "Enlite") {
        config = Enliteconfig
    } else if (product === "Evalgator") {
        config = Evalgatorconfig
    } else if (product === "Finbooks") {
        config = Finbooksconfig
    } else if (product === "Rehash") {
        config = Rehashconfig
    } else if (product === "SeeMyGauzes") {
        config = Gauzesconfig
    } else if (product === "seeMySSD") {
        config = Affconfig
    } else if (product === "SlashQ") {
        config = SlashQconfig
    }

    window.scrollTo(0, 0)

    return (
        <section className="productPage-pc">
            <div className="banner-section-wrapper">
                <div className="banner-section-container align-center">
                    <div className={`logo-title${product}-pc`}>
                        <img src={config["logo"]} alt="Product logo" className={`logo${product}-pc`}/>
                        <div className="product-catch-description-container-pc"> 
                            <p className={`catch-copy${product}-pc`}>{config["catch_phrase"][language]}</p>
                            <div className={`catch-copy-2${product}-pc`}>
                                <p className="catch-copy-2__part1-pc">{config["catch_description_1"][language]}</p>
                                <p className="catch-copy-2__part2-pc">{config["catch_description_2"][language]}</p>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
            
            <div className={`about-section-wrapper-${product}`}>
                <div className="about-section-container align-center">
                    <div className={`about${product}-pc`}>
                        <div className="about-product-section-wrapper">
                            <div className="about-product-text-container">
                            <p className={`About-title${product}-pc`}>About</p>
                                <p className={`About-subtitle${product}-pc`}>{config["subtitle"][language]}</p>
                        
                                <div className={`product-description${product}-pc`}>
                                    <p className="product-description__text-pc">
                                    {config["product_description_1"][language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="decoration-image-container">
                            <img src={config["decoration"]} alt="" className="decoration-pc"/> 
                        </div>           
                    </div>
                </div>
            </div>
            
            <div className="solution-section-wrapper">
                <div className="solution-section-container align-center">
                    <div className={`solution${product}-pc`}>
                        <p className={`solution-title${product}-pc`}>Solution</p>
                        <p className="solution-subtitle-pc">ソリューション</p>
                        <ul className={`solution-list${product}-pc`}>
                        <li><b>{config["product_solution_1"][language]}</b><br/>{config["product_solution_1.1"][language]}</li>
                        <li><b>{config["product_solution_2"][language]}</b><br/>{config["product_solution_2.1"][language]}</li>
                        <li><b>{config["product_solution_3"][language]}</b><br/>{config["product_solution_3.1"][language]}</li>
                        <li><b>{config["product_solution_4"][language]}</b><br/>{config["product_solution_4.1"][language]}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="product-button-pc-wrapper">
                <div className= "product-button-pc align-center">
                    {config["show_website"] && <button type="button" className={`WebButton${product}`} onClick={() => { window.location.href = config["website_url"] } }>{config["website_button"][language]}</button>}
                    {config["show_brochure"] && <button type="button"className={`BrochureButton${product}`} onClick={() => { window.location.href = config["brochure_url"][language] } }>{config["brochure_button"][language]}</button>}
                </div>
            </div>
            {/* Waiting Mai san's update about this section */}
            {/* <div className={`method-of-use${product}`}>
                <p className={`method-of-use-title${product}`}>Method of Use</p>
                <p className={`method-of-use-subtitle${product}`}>使い方</p>
                <img src="/productPage/seeMyGauzes_2.svg" alt="" className={`method-of-use-imge${product}`}/>
            </div> */}
        </section>
    )
}


export  {PCProductPage}