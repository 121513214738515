
import React, {useState} from 'react'
import './styles.css'

function PCHeaderDropDown({children}) {

    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const [rotation, setRotation] = useState(0);
    
    const handleMouseEnter = ()=>{
        setIsDropDownVisible(true);
        setRotation(rotation === 0 ? 180 : 0); 
    }

    const handleMouseLeave = () =>{
        setIsDropDownVisible(false)
        setRotation(rotation === 0 ? 180 : 0); 
    }
  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className='drop-down-container'>
        <p className="menu-title2-pc">Our Products
            <img 
                src="/menu/arrowExpandMenu.svg" 
                alt="Arrow Icon" 
                className="arrowExpandMenu"
                style={{ transform: `rotate(${rotation}deg)` }} 
            />
        </p>
        <p className="menu-subtitle2-pc"> 製品一覧</p>  
        {isDropDownVisible && (
            <div className="dropdown-menu-item-pc"> 
                {children}
            </div>
        )}    
    </div>)
}

export { PCHeaderDropDown }
