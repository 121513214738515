import React from "react"
import './SeeMyMachines.css'
import SeeMyconfig from "./SeeMyMachines.json"

function SeeMyMachines({language}){
    let config=SeeMyconfig

    window.scrollTo(0, 0)

    return (
        <div className="SectionSeeMyProduct">
        <div className="SeeMyMachines">
            <img src="/productPage/SeeMyMachines/SeeMyMachines.png" className="SeeMyLogo" alt="SeeMyMachines"></img>
            <p className="SeeMyDescription">{config["SeeMyDescription_1"][language]}<br/>{config["SeeMyDescription_2"][language]}</p>    
            <p className="SeeMyDescription2">{config["SeeMyDescription2_1"][language]}<br/>{config["SeeMyDescription2_2"][language]}<br/>{config["SeeMyDescription2_3"][language]}</p>    

        </div>
        {/* SeeMy-TransporterManagement */}
        <div className="SeeMyTrans">
            <div className="SectionSeeMyTrans">
            <img src="/productPage/SeeMyMachines/SeeMyPorters.png" className="TransLogo" alt="SeeMyMachines"></img>
            </div>
            <p className="TransTitile">{config["TransTitle1"][language]}</p>
            <img src="/productPage/SeeMyMachines/TransDec.png" className="TransDec" alt="SeeMyMachines"></img>
            <div className="AboutTrans">
            <p className="TransAbout">About</p>
            <p className="TransAboutTitle">プロダクトについて</p>
            <p className="TransDescription">{config["TransDescription1"][language]}</p>
            </div>
            <div className="TransButton">
                <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/transporter-management" } }>{config["website_button"][language]}</button>
                <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["trans-brochure"][language] }  }>{config["brochure_button"][language]}</button>
            </div>
        </div>

       {/* SeeMy-TaskManagement */}
       <div className="SeeMyTask">
            <div className="SectionTask">
            <img src="/productPage/SeeMyMachines/porters.svg" className="TransLogo" alt="SeeMyMachines"></img>
            </div>
            <p className="TransTitile">{config["TaskTitle1"][language]}</p>
            <img src="/productPage/SeeMyMachines/TaskDec.png" className="TaskDec" alt="SeeMyMachines"></img>
            <div className="AboutTask">
            <p className="TaskAbout">About</p>
            <p className="TaskAboutTitle">プロダクトについて</p>
            <p className="TaskDescription">{config["TaskDescription1"][language]}</p>
            </div>
            <div className="TaskButton">
                <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/task-management" } }>{config["website_button"][language]}</button>
                <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["task-brochure"][language] } }>{config["brochure_button"][language]}</button>
            </div>

        {/* SeeMy-Gauzes */}
        <div className="SeeMyGauzes">
            <div className="SectionGauzes">
            <img src="/productPage/SeeMyMachines/seeMyGauzes.svg" className="GauzesLogo" alt="SeeMyMachines"></img>
            </div>
            <p className="GauzesTitile">{config["GauzesTitile1"][language]}</p>
            <img src="/productPage/SeeMyMachines/GauDec.png" className="GauzesDec" alt="SeeMyMachines"></img>
            <div className="AboutGauzes">
            <p className="GauzesAbout">About</p>
            <p className="GauzesAboutTitle">プロダクトについて</p>
            <p className="GauzesDescription">{config["GauzesDescription1"][language]}</p>
            </div>
            <div className="GauzesButton">
                <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/surgicalSpongeTracking" } }>{config["website_button"][language]}</button>
                <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["gauzes-brochure"][language] } }>{config["brochure_button"][language]}</button>
            </div>
        </div>
        {/* SeeMyーSSD */}
        <div className="SeeMySSD">
            <div className="SectionSSD">
            <img src="/productPage/SeeMyMachines/seeSSD1.svg" className="SsdLogo" alt="SeeMyMachines"></img>
            </div>
            <p className="SsdTitile">{config["SsdTitile1"][language]}</p>
            <img src="/productPage/SeeMyMachines/SsdDec.png" className="SsdDec" alt="SeeMyMachines"></img>
            <div className="AboutSsd">
            <p className="SsdAbout">About</p>
            <p className="SsdAboutTitle">プロダクトについて</p>
            <p className="SsdDescription">{config["SsdDescription"][language]}</p>
            </div>
            <div className="SSDButton">
                <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/cssdWorkflowManagement" } }>{config["website_button"][language]}</button>
                <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["ssd-brochure"][language]} }>{config["brochure_button"][language]}</button>
            </div>
        </div>
        </div>
        </div>
        
    )
}
export {SeeMyMachines}