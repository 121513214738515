import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Home } from "./pages/Home/Home";
import { Evalgator } from './pages/Products/Evalgator.jsx';
import { PCHome } from "./pages/PCHome/PCHome";
import { ProductPage } from "./pages/Products/ProductPage";
import { SeeMyMachines } from "./pages/Products/SeeMyMachines.jsx";
import { PCProductPage } from "./pages/Products/PCProducts/PCProductPage";
import { EvalgatorPC } from "./pages/Products/PCProducts/EvalgatorPC";
import { SeeMyMachinesPC } from "./pages/Products/PCProducts/SeeMyMachinesPC";

import { Footer, Header, PCHeader } from "./components";

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  const [language, setLanguage] = useState("jp")
  let isPC = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 700

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
      <div>
        {isPC ? <PCHeader language={language} setLanguage={setLanguage} />: <Header language={language} setLanguage={setLanguage} />}
        <Routes>
          <Route path="/products/cross-channel" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"Crosschannel"} /> : <ProductPage language={language} setLanguage={setLanguage}  product={"Crosschannel"}/>} />
          <Route path="/products/affiliate" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"Affiliate"}/> : <ProductPage language={language} setLanguage={setLanguage} product={"Affiliate"}/>} />
          <Route path="/products/enlite" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"Enlite"}/> : <ProductPage language={language} setLanguage={setLanguage} product={"Enlite"}/>} />
          <Route path="/products/evalgator" element={isPC ? <EvalgatorPC language={language} setLanguage={setLanguage} product={"Evalgator"} /> : <Evalgator language={language} setLanguage={setLanguage} />} />
          <Route path="/products/finbooks" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"Finbooks"} /> : <ProductPage language={language} setLanguage={setLanguage} product={"Finbooks"} />} />
          <Route path="/products/rehash" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"Rehash"}/> : <ProductPage language={language} setLanguage={setLanguage} product={"Rehash"}/>} />
          <Route path="/products/see-my-machines" element={isPC ? <SeeMyMachinesPC language={language} setLanguage={setLanguage}  /> :<SeeMyMachines language={language} setLanguage={setLanguage}  />} />
          <Route path="/products/see-my-ssd" element={<ProductPage language={language} setLanguage={setLanguage} product={"seeMySSD"}/>} />
          <Route path="/products/slashq" element={isPC ? <PCProductPage language={language} setLanguage={setLanguage} product={"SlashQ"}/> : <ProductPage language={language} setLanguage={setLanguage} product={"SlashQ"}/>} />
          <Route path="*" element={isPC ?  <PCHome language={language} setLanguage={setLanguage} /> : <Home language={language} setLanguage={setLanguage} />} />
        </Routes>
        <Footer/>
      </div>
  );
}
export default App;
