import React, {useState}from "react";
import joinClasses from "../../utils/joinClasses";
import './styles.css'
import {Button} from "../"

function Accordion({id, logo,title, laptopIcon, buttonText, onButtonClick, children}){

    const [expanded, setExpanded] = useState(false);
    
    const toggleExpanded = () => {
        setExpanded(!expanded)
    }
    return (
        <div id={id} className={joinClasses("accordion", expanded? "accordion-expanded": "")} onClick={toggleExpanded}>
            
            <div className="accordion-logo-container">
                <img src={logo}  alt={title} className="accordion-logo--img" />
            </div>
            
            <h4 className="accordion-header--title">{title}</h4>
            { expanded && (
                <div className="accordion-content">
                    <img src={laptopIcon} alt={title} className="accordion-content--img" />
                    <div className="accordion-content--description"> 
                      {children}
                    </div>
                    <Button variant="bordered" className="accordion-content--button" icon="/button-arrow-red.svg" buttonText={buttonText} onClick={onButtonClick} />
                </div>  
            )}
            <div className={joinClasses("accordion-header--arrow", expanded? "accordion-header--arrow--expanded": "")} >
                <img src="/productPage/Vector.svg" alt="arrow "/>
            </div>
            </div>
        
        
    )
}

export {Accordion}
