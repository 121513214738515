import {React, useState} from "react";
import './styles.css';
import { getLocalization } from "../../translations"
import { Link, useNavigate } from "react-router-dom";
import { PCHeaderDropDown } from '../index'

function PCHeader({language, setLanguage}){
    const navigate= useNavigate();

    function handleClick(){
      document.getElementsByTagName("html")[0].lang = document.getElementsByTagName("html")[0].lang === "jp" ? "en" : "jp"
        //Logic to toggle the language used in the page
        setLanguage(language === "jp" ? "en" : "jp")
    }
    
    const jumpAboutCompany = () => {
      window.location.replace('https://www.qburst.com/jp-jp/company/profile?utm_source=website&utm_medium=productcatalog&utm_campaign=japanitweek2023');
    };

    return(
      <div className="header-pc-wrapper">
        <header className="header-pc">
            <a href="/" className="header--qburst-logo-icon-pc">
                <img src="/Logos/QBurst_Logo.svg" alt="QBurst" />
            </a>
            
            {/* Navigation options  */}
            <Link className="about-us-pc" onClick={() => {navigate({ pathname: "/" })}}> 
              <p className="menu-title1-pc">About Us</p>
              <p className="menu-subtitle1-pc">私たちについて</p>
            </Link>
          
          <PCHeaderDropDown>
            <ul>
              <li>

                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=evalgator" })
                  }}>
                  Evalgator
                  <span className="description-pc">{getLocalization(language, "evalgator_product_description")}</span>
                </p>
                
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=cross-channel" })}}>
                  Cross Channel
                  <span className="description-pc">{getLocalization(language, "cross_channel_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=enlite" })}}>
                  Enlite
                  <span className="description-pc">{getLocalization(language, "enlite_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=rehash" })}} >
                  Rehash Channel
                  <span className="description-pc">{getLocalization(language, "rehash_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=finbooks" })}}>
                  Finbooks
                  <span className="description-pc">{getLocalization(language, "finbooks_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=slashq" })}}>
                  SlashQ
                  <span className="description-pc">{getLocalization(language, "slashq_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={()=>{
                  navigate({ pathname: "/", search: "?product=affiliate" })}}>
                  Qburst Affliliate
                  <span className="description-pc">{getLocalization(language, "affiliate_product_description")}</span>
                </p>
              </li>
              <li>
                <p className="content-pc" onClick={() => {
                  navigate({ pathname: "/", search: "?product=seeMyMachine" })
                  }}>
                  See My Machine
                  <span className="description-pc">{getLocalization(language, "seemymachine_product_description")}</span>
                </p>
              </li>
            </ul>
          </PCHeaderDropDown>
          <Link className="aboutCompany-pc" onClick={jumpAboutCompany}>
          <p className="menu-title3-pc">Our Company</p>
          <p className="menu-subtitle3-pc" >会社概要</p>
          </Link>
          <button className="header--language-pc" onClick={handleClick}>{language === "jp" ? "English" : "日本語"}</button>
        </header>
      </div>
    )
}

export { PCHeader }
