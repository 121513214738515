import React from "react"
import './EvalgatorPC.css'
import Evalgator from "./PCEvalgator.json"
function EvalgatorPC({language}){
    const config = Evalgator
    const product ="Evalgator"
    window.scrollTo(0, 0)

    return (
        <section className="productPage-pc">
            <div className="banner-section-wrapper">
                <div className="banner-section-container align-center">
                    <div className={`logo-title${product}-pc`}>
                        <img src={config["logo"]} alt="Product logo" className={`logo${product}-pc`}/>
                        <div className="product-catch-description-container-pc"> 
                            <p className={`catch-copy${product}-pc`}>{config["catch_phrase"][language]}</p>
                            <div className={`catch-copy-2${product}-pc`}>
                                <p className="catch-copy-2__part1-pc">{config["catch_description_1"][language]}</p>
                                <p className="catch-copy-2__part2-pc">{config["catch_description_2"][language]}</p>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
            
            {/* Evalgator Assessment */}
            <div className={`assessment-section-wrapper-${product}`}>
                <div className="assessment-section-container align-center">
                    <div className={`assessment${product}-pc`}>
                        <div className="assessment-logo-title-wrapper">
                            <div className={`assessment-logo-container`}>
                                <img src="/productPage/Evalgator/assessLogo.svg" className="assessment-logo-pc" alt="Assessment"></img>
                            </div>

                            <div className={`assessment-catch-phrase-wrapper`}>
                                <p className="assessment-title-pc">{config["AssessmentTitle1"][language]}<br/>{config["AssessmentTitle2"][language]}</p>
                            </div>
                        </div>
                        <div className="decoration-image-container-pc">
                            <img src="/productPage/Evalgator/AssessDec.webp" className="assessment-dec-pc" alt="Assessment"></img>
                        </div>           
                    </div>
               </div>
            </div>
            
            <div className="solution-section-wrapper">
                <div className="solution-section-container align-center">
                    <div className={`solution${product}-pc`}>
                        <p className={`solution-title${product}-pc`}>Solution</p>
                        <p className="solution-subtitle-pc">ソリューション</p>
                        <ul className={`solution-list${product}-pc`}>
                            <li><b>{config["Assessment_solution_1"][language]}</b><br/>{config["Assessment_solution_1.1"][language]}</li>
                            <li><b>{config["Assessment_solution_2"][language]}</b><br/>{config["Assessment_solution_2.1"][language]}</li>
                            <li><b>{config["Assessment_solution_3"][language]}</b><br/>{config["Assessment_solution_3.1"][language]}</li>
                            <li><b>{config["Assessment_solution_4"][language]}</b><br/>{config["Assessment_solution_4.1"][language]}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="product-button-pc-wrapper">
                <div className="assessment-button-pc align-center">
                    <button type="button" className="AssessmentWebButton" onClick={() => { window.location.href = "https://www.evalgator.com/online-assessment-platform" } }>{config["website_button"][language]}</button>
                </div>
            </div>

            {/* Evalgator Interview */}
            <div className={`interview-section-wrapper-${product}`}>
                <div className="interview-section-container align-center">
                    <div className={`interview${product}-pc`}>
                        <div className="interview-logo-title-wrapper">
                            <div className={`interview-logo-container`}>
                                <img src="/productPage/Evalgator/interviewLogo.svg" className="interview-logo-pc" alt="Interview"></img>
                            </div>

                            <div className={`interview-catch-phrase-wrapper`}>
                                <p className="interview-title-pc">{config["InterviewTitle1"][language]}<br/>{config["InterviewTitle2"][language]}</p>
                            </div>
                        </div>
                        <div className="decoration-image-container-pc">
                            <img src="/productPage/Evalgator/interviewDec.webp" className="interview-dec-pc" alt="Interview"></img>
                        </div>           
                    </div>
               </div>
            </div>
    
            <div className="solution-section-wrapper">
                <div className="solution-section-container align-center">
                    <div className={`solution${product}-pc`}>
                        <p className={`solution-title${product}-pc`}>Solution</p>
                        <p className="solution-subtitle-pc">ソリューション</p>
                        <ul className={`solution-list${product}-pc`}>
                            <li><b>{config["Interview_solution_1"][language]}</b><br/>{config["Interview_solution_1.1"][language]}</li>
                            <li><b>{config["Interview_solution_2"][language]}</b><br/>{config["Interview_solution_2.1"][language]}</li>
                            <li><b>{config["Interview_solution_3"][language]}</b><br/>{config["Interview_solution_3.1"][language]}</li>
                            <li><b>{config["Interview_solution_4"][language]}</b><br/>{config["Interview_solution_4.1"][language]}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="product-button-pc-wrapper">
                <div className="interview-button-pc align-center">
                    <button type="button" className="InterviewWebButton" onClick={() => { window.location.href = "https://www.evalgator.com/coding-interview-tool" } }>{config["website_button"][language]}</button>
                    <button type="button"className="InterviewBrochureButton" onClick={() => { window.location.href = config["pcEvalgator-brochure"][language] } }>{config["brochure_button"][language]}</button>
                </div>
            </div>

        </section>
    )
}

export { EvalgatorPC }