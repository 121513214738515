import React from "react"
import './styles.css'
import Affconfig from "./Affiliate.json"
import Crossconfig from "./CrossChannel.json"
import Enliteconfig from "./Enlite.json"
import Finbooksconfig from "./Finbooks.json"
import Gauzesconfig from "./Gauzes.json"
import Rehashconfig from "./Rehash.json"
import SlashQconfig from "./SlashQ.json"


function ProductPage ({product, language}) {
    let config

    if(product === "Crosschannel") {
        config = Crossconfig
    } else if (product === "Affiliate") {
        config = Affconfig
    } else if (product === "Enlite") {
        config = Enliteconfig
    } else if (product === "Finbooks") {
        config = Finbooksconfig
    } else if (product === "Rehash") {
        config = Rehashconfig
    } else if (product === "SeeMyGauzes") {
        config = Gauzesconfig
    } else if (product === "seeMySSD") {
        config = Affconfig
    } else if (product === "SlashQ") {
        config = SlashQconfig
    }

    window.scrollTo(0, 0)

    return (
        <div className={`background${product}`}>
        <section className="productPage">
            <div className={`logo-title${product}`}>
                <img src={config["logo"]} alt="" className={`logo${product}`}/>
                <p className={`catch-copy${product}`}>{config["catch_phrase"][language]}</p>
                <div className={`catch-copy-2${product}`}>
                    <p className="catch-copy-2__part1">{config["catch_description_1"][language]}</p>
                    <p className="catch-copy-2__part2">{config["catch_description_2"][language]}</p>
                </div>
            </div>
            <div className={`about${product}`}>
                <img src={config["decoration"]} alt="" className="decoration"/>
            
                <p className={`About-title${product}`}>About</p>
                <p className={`About-subtitle${product}`}>{config["subtitle"][language]}</p>
        
                <div className={`product-description${product}`}>
                    <p className="product-description__text">
                    {config["product_description_1"][language]}
        
                    </p>
                </div>
            </div>
            
            <div className={`solution${product}`}>
                <p className={`solution-title${product}`}>Solution</p>
                <p className="solution-subtitle">ソリューション</p>
                <ul className={`solution-list${product}`}>
                    <li><b>{config["product_solution_1"][language]}</b><br/>{config["product_solution_1.1"][language]}</li>
                    <li><b>{config["product_solution_2"][language]}</b><br/>{config["product_solution_2.1"][language]}</li>
                    <li><b>{config["product_solution_3"][language]}</b><br/>{config["product_solution_3.1"][language]}</li>
                    <li><b>{config["product_solution_4"][language]}</b><br/>{config["product_solution_4.1"][language]}</li>
                </ul>
            </div>
            {/* Waiting Mai san's update about this section */}
            {/* <div className={`method-of-use${product}`}>
                <p className={`method-of-use-title${product}`}>Method of Use</p>
                <p className={`method-of-use-subtitle${product}`}>使い方</p>
                <img src="/productPage/seeMyGauzes_2.svg" alt="" className={`method-of-use-imge${product}`}/>
            </div> */}
            <div className={`buttonSection${product}`}>
                {config["show_website"] && <button type="button" className={`WebButton${product}`} onClick={() => { window.location.href = config["website_url"] } }>{config["website_button"][language]}</button>}
                {config["show_brochure"] && <button type="button"className={`BrochureButton${product}`} onClick={() => { window.location.href = config["brochure_url"][language] } }>{config["brochure_button"][language]}</button>}
            </div>
        </section>
        </div>
    )
}


export  {ProductPage}