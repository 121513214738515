import React from "react"
import './Evalgator.css'
import Evalgatorconfig from "./Evalgator.json"

function Evalgator({language}){
    let config=Evalgatorconfig

    window.scrollTo(0, 0)

    return (
        <div className="SectionEvalgator">
        <div className="Evalgator">
            <img src="/Logos/Evalgator.svg" className="EvalgatorLogo" alt="EvalgatorLogo"></img>
            <p className="catch-copyEvalgator">{config["catch_phraseEvalgator"][language]}</p>
            <p className="EvalgatorDescription">{config["EvalgatorDescription_1"][language]}<br/>{config["EvalgatorDescription_2"][language]}</p>    
        </div>
        {/* Evalgator Assessment */}
        <div className="Assessment">
            <div className="SectionAssessment">
            <img src="/productPage/Evalgator/assessLogo.svg" className="AssessmentLogo" alt="Assessment"></img>
            </div>
            <p className="AssessmentTitile">{config["AssessmentTitle1"][language]}<br/>{config["AssessmentTitle2"][language]}</p>
            
            <img src="/productPage/Evalgator/AssessDec.webp" className="AssessmentDec" alt="Assessment"></img>
            {/* <div className="AboutAssessment">
            <p className="AssessmentAbout">About</p>
            <p className="AssessmentTitle">プロダクトについて</p>
            <p className="AssessmentDescription">{config["AssessmentDescription1"][language]}</p>
            </div> */}
            <div className="AssessmentSolution">
                <p className="AssessmentSolution1">Solution</p>
                <p className="AssessmentSolution2">ソリューション</p>
                <ul className="AssessmentSolution3">
                    <li><b>{config["Assessment_solution_1"][language]}</b><br/>{config["Assessment_solution_1.1"][language]}</li>
                    <li><b>{config["Assessment_solution_2"][language]}</b><br/>{config["Assessment_solution_2.1"][language]}</li>
                    <li><b>{config["Assessment_solution_3"][language]}</b><br/>{config["Assessment_solution_3.1"][language]}</li>
                    <li><b>{config["Assessment_solution_4"][language]}</b><br/>{config["Assessment_solution_4.1"][language]}</li>
                </ul>
            </div>
            <div className="AssessmentButton">

                <button type="button" className="AssessmentWebButton" onClick={() => { window.location.href = "https://www.evalgator.com/online-assessment-platform" } }>{config["website_button"][language]}</button>
            </div>
        </div>
        {/* Evalgator Interview */}
        <div className="Interview">
            <div className="SectionInterview">
            <img src="/productPage/Evalgator/interviewLogo.svg" className="InterviewLogo" alt="Interview"></img>
            </div>
            <p className="InterviewTitile">{config["InterviewTitle1"][language]}<br/>{config["InterviewTitle2"][language]}</p>
            <img src="/productPage/Evalgator/interviewDec.webp" className="InterviewDec" alt="Interview"></img>
            {/* <div className="AboutInterview">
            <p className="InterviewAbout">About</p>
            <p className="InterviewTitle">プロダクトについて</p>
            <p className="InterviewDescription">{config["InterviewDescription1"][language]}</p>
            </div> */}
            <div className="InterviewSolution">
                <p className="InterviewSolution1">Solution</p>
                <p className="InterviewSolution2">ソリューション</p>
                <ul className="InterviewSolution3">
                    <li><b>{config["Interview_solution_1"][language]}</b><br/>{config["Interview_solution_1.1"][language]}</li>
                    <li><b>{config["Interview_solution_2"][language]}</b><br/>{config["Interview_solution_2.1"][language]}</li>
                    <li><b>{config["Interview_solution_3"][language]}</b><br/>{config["Interview_solution_3.1"][language]}</li>
                    <li><b>{config["Interview_solution_4"][language]}</b><br/>{config["Interview_solution_4.1"][language]}</li>
                </ul>
            </div>
            <div className="InterviewButton">

                <button type="button" className="InterviewWebButton" onClick={() => { window.location.href = "https://www.evalgator.com/coding-interview-tool" } }>{config["website_button"][language]}</button>
                <button type="button"className="InterviewBrochureButton" onClick={() => { window.location.href = config["evalgator-brochure"][language] } }>{config["brochure_button"][language]}</button>

            </div>
        </div>
       
        </div>
        
    )
}
export {Evalgator};