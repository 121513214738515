import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion, MainContent } from "../../components";
import { getLocalization } from "../../translations"
import './styles.css'

function Home({ language }){
 const navigate= useNavigate();
 const { search } = useLocation();

 useEffect(() => {
    if(search) {
        const offset = document.getElementById(search.split("=")[1])?.offsetTop - 50
        window.scrollTo(0, offset)
     }
  }, [search]);
 
 return(

    <div className="home">
        <MainContent language={language} />
        <div className="product-main-title">
            <h3 className="product-into--title">OUR PRODUCTS</h3>
            <p className="product-into--title-background">PRODUCTS</p>
            <h4 className="product-into--title2">{getLocalization(language, "product_title")}</h4>
        </div>
        
        <div className="OfferingProduct">
            <p>{getLocalization(language, "OfferingProduct1")}<br/>{getLocalization(language, "OfferingProduct2")}<br/>{getLocalization(language, "OfferingProduct3")}</p>
            </div>

        {/* Cross Channel */}
        <Accordion 
            id="cross-channel"
            logo="/Logos/Crosschannel.svg" 
            title={getLocalization(language, "crosschannel_title")} 
            laptopIcon="/productPage/laptop/cross-channel.svg"
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/cross-channel")}>
            <div>
                <p>{getLocalization(language, "crosschannel_description_1")}</p>
                <p>{getLocalization(language, "crosschannel_description_1.1")}</p>    
            </div>
        </Accordion>

        {/* Evalgator */}
        <Accordion 
            id="evalgator"
            logo="/Logos/Evalgator.svg" 
            title={getLocalization(language, "evalgator_title")} 
            laptopIcon="/productPage/laptop/evalgator.svg" 
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/evalgator")}>
            <div>
                <p>{getLocalization(language, "evalgator_description_1")}<br/>{getLocalization(language, "evalgator_description_1.1")}<br/>{getLocalization(language, "evalgator_description_1.2")}</p>    
            </div>
    
        </Accordion>

        {/* Partner Front */}
        <Accordion 
            id="affiliate"
            logo="/Logos/PartnerFront.svg" 
            title={getLocalization(language, "affiliate_title")} 
            laptopIcon="/productPage/laptop/affiliate.svg" 
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/affiliate")}>

    
            <div>
                <p>{getLocalization(language, "affiliate_description_1")}<br/>{getLocalization(language, "affiliate_description_1.1")}</p>    
            </div>
        </Accordion>

         {/* Finbook */}
         <Accordion 
            id="finbooks"
            logo="/Logos/Finebooks2.svg" 
            title={getLocalization(language, "finbook_title")} 
            laptopIcon="/productPage/laptop/finbooks.svg"
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/finbooks")}>
        
            <div>
                <p>{getLocalization(language, "finbook_description_1")}<br/>{getLocalization(language, "finbook_description_1.1")}<br/>{getLocalization(language, "finbook_description_1.2")}</p>    
            </div>
        </Accordion>

        {/* SlashQ */}
        <Accordion 
            id="slashq"
            logo="/Logos/SlashQ.svg" 
            title={getLocalization(language, "slashq_title")} 
            laptopIcon="/productPage/laptop/slashq.svg" 
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/slashq")}>


             <div>
                <p>{getLocalization(language, "slashq_description_1")}<br/>{getLocalization(language, "slashq_description_1.1")}</p>    
            </div> 
        
        </Accordion>

         {/* See My Machines */}
         <Accordion 
            id="seeMyMachine"
            logo="/Logos/SeeMylogo.svg" 
            title={getLocalization(language, "seemymachine_title")} 
            laptopIcon="/productPage/laptop/seeMyMachine.svg"
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/see-my-machines")}>

            <div>
                <p>{getLocalization(language, "seemymachine_description_1")}<br/>{getLocalization(language, "seemymachine_description_1.1")}</p>    
            </div> 
        </Accordion>



        {/* Enlite */}
        <Accordion 
            id="enlite"
            logo="/Logos/Enlite.svg" 
            title={getLocalization(language, "enlite_title")} 
            laptopIcon="/productPage/laptop/enlite.svg"
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/enlite")}>

            <div>
                <p>{getLocalization(language, "enlite_description_1")}<br/>{getLocalization(language, "enlite_description_1.1")}</p>    
            </div> 
        
        </Accordion>

        {/* Rehash */}
        <Accordion 
            id="rehash"
            logo="/Logos/Rehash.svg" 
            title={getLocalization(language, "rehash_title")} 
            laptopIcon="/productPage/laptop/rehash.svg"
            buttonText="View More" 
            onButtonClick={()=>navigate("/products/rehash")}>

            <div>
                <p>{getLocalization(language, "rehash_description_1")}<br/>{getLocalization(language, "rehash_description_1.1")}<br/>{getLocalization(language, "rehash_description_1.2")}</p>    
            </div> 
        
        </Accordion>
    </div>
 )   
}

export { Home } 