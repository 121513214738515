const localizations = {
    "en": {
        "text_about_us": "About Us",
        "motto1":"Transition ",
        "motto2":"to a future-ready ",
        "motto3":"Digital enterprise",
        "motto4":"",
        "motto5":"",
        "text_description": "Since our founding in 2004, we have continued to adapt to rapidly evolving technology trends, providing a wide range of software solutions and IT consulting. As Asia's fastest growing technology company, we have expertise in a wide range of areas, including software architects, developers, designers, UX engineers, QA specialists, business analysts, DevOps engineers and data scientists. We build end-to-end product development with a team of skilled and experienced professionals.",
        "text_subheading": "私たちについて",
        "product_title": "製品情報",
        "OfferingProduct1":"We provide products",
        "OfferingProduct2":"to support customers in a variety of sectors,",
        "OfferingProduct3":"including healthcare, accounting, HR and insurance.",
        "evalgator_title": "Recruitment Assistance Platform",
        "evalgator_description_1": "An all-in-one solution for",
        "evalgator_description_1.1": "streamlining recruitment",


        "crosschannel_title": "Marketing Platform",
        "crosschannel_description_1": "Insight-based content marketing tool",
        
        "enlite_title": "Hotel Distribution Platform",
        "enlite_description_1": "A cloud-based hotel booking platform offers seamless and efficient reservation services",

        "rehash_title": "Low-Code Insurance Platform ",
        "rehash_description_1": " A purpose-built development platform ",
        "rehash_description_1.1": "created specifically ",
        "rehash_description_1.2": "for the life insurance industry",


        "finbook_title": "Cloud Financial Platform",
        "finbook_description_1": "Cloud accounting software  ",
        "finbook_description_1.1": "for managing and keeping ",
        "finbook_description_1.2": "track of financial transactions",
        "pcfinbook_description_1": "Cloud accounting software  ",
        "pcfinbook_description_1.1": "for managing and keeping ",
        "pcfinbook_description_1.2": "track of financial transactions",



       
        "slashq_title": "Digital Queue Management System",
        "slashq_description_1": "One-stop digital solution",
        "slashq_description_1.1": "for eﬃcient crowd management",

        
        "affiliate_title": "Affiliate Marketing ",
        "affiliate_description_1": "Build, monitor, and expand",
        "affiliate_description_1.1": "your affiliate marketing strategy",
        "pcaffiliate_description_1": "Build, monitor, and expand",
        "pcaffiliate_description_1.1": "your affiliate marketing strategy",


        "seemymachine_title": "Platform for DX in Healthcare Industry",
        "seemymachine_description_1": "Enables tracking of various targets,",
        "seemymachine_description_1.1": "such as materials, employees, tasks, etc.",

        
        "evalgator_product_description": "Recruitment Assistance Platform",
        "cross_channel_product_description": "Marketing Platform",
        "enlite_product_description": "Hotel Distribution Platform",
        "rehash_product_description": "Low-code Insurance Platform ",
        "slashq_product_description": "Digital Queue Management System",
        "finbooks_product_description": "Cloud ﬁnancial platform",
        "affiliate_product_description": "Affiliate Markeing",
        "seemymachine_product_description": "DX in Healthcare Industry",
    },
    "jp": {
        "text_about_us": "私たちについて",
        "motto1":"未来に向けた",
        "motto2":"デジタル企業への移行を",
        "motto3":"お手伝いします",
        "motto4":"",
        "motto5":"",
        "text_description": "2004年の設立以来、急速に進化するテクノロジーのトレンドに適応し続け、多様なソフトウェア・ソリューションとITコンサルティングを提供しております。アジアで急成長しているテクノロジー企業として、ソフトウェアアーキテクト、開発者、デザイナー、UXエンジニア、QAスペシャリスト、ビジネスアナリスト、DevOpsエンジニア、データサイエンティストなど、多岐にわたる分野において専門性を有しており、熟練した経験豊富な専門家からなるチームでエンドツーエンドの製品開発を構築しております。",
        "text_subheading": "私たちについて",
        "product_title": "製品情報",
        "OfferingProduct1":"ヘルスケア・会計・HR・保険業界などさまざまな分野で",
        "OfferingProduct2":"お客さまをサポートする製品をご提供しております。",

        
        "evalgator_title": "採用支援プラットフォーム",
        "evalgator_description_1": "採用を効率化し最適な人材を見つけるための",
        "evalgator_description_1.1": "オールインワンソリューション",

        "crosschannel_title": "コンテンツマーケティングツール",
        "crosschannel_description_1": "人工知能と機械学習を組み合わせた",
        "crosschannel_description_1.1": "マーケティングオートメーションツール",
       
        "enlite_title": "ホテル流通プラットフォーム",
        "enlite_description_1": "クラウドネイティブ",
        "enlite_description_1.1": "B to Bプラットフォーム",

    
        "rehash_title": "保険サービス向けローコードプラットフォーム",
        "rehash_description_1": "保険業界向けに開発された",
        "rehash_description_1.1": "専用開発プラットフォーム",

        "finbook_title": "クラウド会計ソフトウェア",
        "finbook_description_1": "財務取引を管理・記録保持出来るように",
        "finbook_description_1.1": "設計されたクラウド会計ソフトウェア",
        "pcfinbook_description_1": "財務取引を管理・記録保持出来るように設計された",
        "pcfinbook_description_1.1": "クラウド会計ソフトウェア",



        
        "slashq_title": "デジタルキュー管理システム",
        "slashq_description_1": "ワンストップソリューションによる",
        "slashq_description_1.1": "効率的な混雑状況の管理",

        "affiliate_title": "アフィリエイトマーケティング",
        "affiliate_description_1": "アフィリエイトマーケティング戦略の",
        "affiliate_description_1.1": "構築から実用的な運営を実現",
        "pcaffiliate_description_1": "アフィリエイトマーケティング戦略",
        "pcaffiliate_description_1.1": "の構築から実用的な運営を実現",
        
        

        
        "seemymachine_title": "ヘルスケア業界向けDXプラットフォーム",
        "seemymachine_description_1": "資材、従業員、タスク等",
        "seemymachine_description_1.1": "様々な対象のトラッキングが可能",


        "evalgator_product_description": "採用支援プラットフォーム",
        "cross_channel_product_description": "コンテンツマーケティング",
        "enlite_product_description": "ホテル流通プラットフォーム",
        "rehash_product_description": "保険サービス向けLCP",
        "slashq_product_description": "デジタルキュー管理システム",
        "finbooks_product_description": "クラウド会計ソフトウェア",
        "affiliate_product_description": "アフィリエイトマーケティング",
        "seemymachine_product_description": "ヘルスケア業界向けDX",
    }
}

export const getLocalization = (language, key) => {
    return localizations[language][key]
}