import React from "react";
import joinClasses from "../../utils/joinClasses";
import './styles.css'

// varints: bordered(default) | solid 
// An object that maps the variant values to the class names
const variantClasses = {
    solid: "button-solid",
    bordered: "button-bordered",
    plain: "button-plain",
  };
  
const Button = ({icon, buttonText, onClick, className, variant})=>{
    return (
        <button className={joinClasses("button", className, variantClasses[variant])} onClick={onClick}>
            <img src={variant==="solid"? "/vector.svg" : icon} alt="button icon" className="button-vector-icon" />
            <span className="button-text">{buttonText}</span>
        </button>)
}

export {Button}
