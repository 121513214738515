import React, {useState}from "react";
import joinClasses from "../../utils/joinClasses";
import './styles.css'
import {Button} from ".."

function HoverAccordion({id, logo,title, laptopIcon, buttonText, onButtonClick, children}){

    const [expanded, setExpanded] = useState(false);
    
    const handleMouseEnter = ()=>{
        setExpanded(true);
    }
    const handleMouseLeave = ()=>{
        setExpanded(false)
    }

    return (
        <div id={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={joinClasses("hover-accordion", expanded? "hover-accordion--expanded": "")}>
            
            <div className={joinClasses("hover-accordion-heading-container", expanded? "hover-accordion-heading-container--expanded":"")}>
                <div className="hover-accordion-logo-container">
                    <img src={logo}  alt={title} className="hover-accordion-logo--img" />
                </div>
                <h4 className="hover-accordion-header--title">{title}</h4>
            </div>
            {
                expanded ? (
                        
                        <div className="hover-accordion-content">
                            <div className="hover-accordion-content--description"> 
                            {children}
                            </div>
                        </div>
                    )
                    : (
                        <div className="hover-accordion-image-container">
                            <div className="laptop-image-container">
                                <img src={laptopIcon} alt={title} className="accordion-content--img" />
                            </div>
                        </div>
                    )
            }
            <Button variant={expanded? "solid" : "plain"} className="hover-accordion-content--button" icon="/plain-button-icon.svg" buttonText={buttonText} onClick={onButtonClick} />
        
        </div>
        
        
    )
}

export {HoverAccordion}
