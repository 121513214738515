 import React from 'react'
 import './SeeMyMachinePC.css'
 import SeeMyconfig from './PCSeeMyMachines.json'

 const SeeMyMachinesPC = ({language}) => {
    let config=SeeMyconfig
    let product="SeeMyMachines"

    window.scrollTo(0, 0)
    return (
        <section className="productPage-pc">
                <div className="banner-section-wrapper">
                    <div className="banner-section-container align-center">
                        <div className={`logo-title${product}-pc`}>
                            <img src={config["logo"]} alt="Product logo" className={`logo${product}-pc`}/>
                            <div className="product-catch-description-container-pc"> 
                                <p className={`catch-copy${product}-pc`}>{config["SeeMyDescription_1"][language]}<br/>{config["SeeMyDescription_2"][language]}</p>
                                <div className={`catch-copy-2${product}-pc`}>
                                    <p className="catch-copy-2__part1-pc">{config["SeeMyDescription2_1"][language]}<br/>{config["SeeMyDescription2_2"][language]}</p>
                                    <p className="catch-copy-2__part2-pc">{config["SeeMyDescription2_3"][language]}</p>
                                </div> 
                            </div> 
                        </div>
                    </div>
                </div>
                
                {/* SeeMy-TransporterManagement */}
                <div className='product-wrapper-pc'>
                    <div className="banner-section-wrapper">
                        <div className="banner-section-container align-center">
                        <div className="section-logo-container-pc">
                            <img src="/productPage/SeeMyMachines/SeeMyPorters.png" className="section-product-logo" alt="SeeMyMachines"></img>
                        </div>
                        <p className="section-product-title">{config["TransTitle1"][language]}</p>
                        </div>
                    </div>
                </div>
                <div className={`about-section-wrapper-${product}`}>
                <div className="about-section-container align-center">
                    <div className={`about${product}-pc`}>
                        <div className="about-product-section-wrapper">
                            <div className="about-product-text-container">
                            <p className={`About-title${product}-pc`}>About</p>
                                <p className={`About-subtitle${product}-pc`}>プロダクトについて</p>
                        
                                <div className={`product-description${product}-pc`}>
                                    <p className="product-description__text-pc">
                                    {config["TransDescription1"][language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="decoration-image-container">
                            <img src="/productPage/SeeMyMachines/TransDec.png" alt="" className="decoration-pc"/> 
                        </div>           
                    </div>
                </div>
            </div>
            <div className="product-button-pc-wrapper">
                <div className="product-button-pc align-center">
                    <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/transporter-management" } }>{config["website_button"][language]}</button>
                    <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["Transporterpc-brochure"][language] } }>{config["brochure_button"][language]}</button>
                </div>
            </div>
             {/* SeeMy-TaskManagement */}
             <div className='product-wrapper-pc'>
                    <div className="banner-section-wrapper">
                        <div className="banner-section-container align-center">
                        <div className="section-logo-container-pc">
                            <img src="/productPage/SeeMyMachines/porters.svg" className="section-product-logo" alt="SeeMyMachines"></img>
                        </div>
                        <p className="section-product-title">{config["TaskTitle1"][language]}</p>
                        </div>
                    </div>
                </div>
                {/* about section */}
                <div className={`about-section-wrapper-${product}`}>
                <div className="about-section-container align-center">
                    <div className={`about${product}-pc`}>
                        <div className="about-product-section-wrapper">
                            <div className="about-product-text-container">
                            <p className={`About-title${product}-pc`}>About</p>
                                <p className={`About-subtitle${product}-pc`}>プロダクトについて</p>
                        
                                <div className={`product-description${product}-pc`}>
                                    <p className="product-description__text-pc">
                                    {config["TaskDescription1"][language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="decoration-image-container">
                            <img src="/productPage/SeeMyMachines/TaskDec.png" alt="" className="decoration-pc"/> 
                        </div>           
                    </div>
                </div>
            </div>
            <div className="product-button-pc-wrapper">
                <div className="product-button-pc align-center">
                    <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/task-management" } }>{config["website_button"][language]}</button>
                    <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["Taskpc-brochure"][language] } }>{config["brochure_button"][language]}</button>
                </div>
            </div>

            {/* SeeMy-Gauzes */}
            <div className='product-wrapper-pc'>
                    <div className="banner-section-wrapper">
                        <div className="banner-section-container align-center">
                        <div className="section-logo-container-pc">
                            <img src="/productPage/SeeMyMachines/seeMyGauzes.svg" className="section-product-logo" alt="SeeMyMachines"></img>
                        </div>
                        <p className="section-product-title">{config["GauzesTitile1"][language]}</p>
                        </div>
                    </div>
                </div>
                <div className={`about-section-wrapper-${product}`}>
                <div className="about-section-container align-center">
                    <div className={`about${product}-pc`}>
                        <div className="about-product-section-wrapper">
                            <div className="about-product-text-container">
                            <p className={`About-title${product}-pc`}>About</p>
                                <p className={`About-subtitle${product}-pc`}>プロダクトについて</p>
                        
                                <div className={`product-description${product}-pc`}>
                                    <p className="product-description__text-pc">
                                    {config["GauzesDescription1"][language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="decoration-image-container">
                            <img src="/productPage/SeeMyMachines/GauDec.png" alt="" className="decoration-pc"/> 
                        </div>           
                    </div>
                </div>
            </div>
            <div className="product-button-pc-wrapper">
                <div className="product-button-pc align-center">
                    <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/surgicalSpongeTracking" } }>{config["website_button"][language]}</button>
                    <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["Gauzespc-brochure"][language]} }>{config["brochure_button"][language]}</button>
                </div>
            </div>
            {/* SeeMyーSSD */}
            <div className='product-wrapper-pc'>
                    <div className="banner-section-wrapper">
                        <div className="banner-section-container align-center">
                        <div className="section-logo-container-pc">
                            <img src="/productPage/SeeMyMachines/seeSSD1.svg" className="section-product-logo" alt="SeeMyMachines"></img>
                        </div>
                        <p className="section-product-title">{config["SsdTitile1"][language]}</p>
                        </div>
                    </div>
                </div>
                <div className={`about-section-wrapper-${product}`}>
                <div className="about-section-container align-center">
                    <div className={`about${product}-pc`}>
                        <div className="about-product-section-wrapper">
                            <div className="about-product-text-container">
                            <p className={`About-title${product}-pc`}>About</p>
                                <p className={`About-subtitle${product}-pc`}>プロダクトについて</p>
                        
                                <div className={`product-description${product}-pc`}>
                                    <p className="product-description__text-pc">
                                    {config["SsdDescription"][language]}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="decoration-image-container">
                            <img src="/productPage/SeeMyMachines/SsdDec.png" alt="" className="decoration-pc"/> 
                        </div>           
                    </div>
                </div>
            </div>
            <div className="product-button-pc-wrapper">
                <div className="product-button-pc align-center">
                <button type="button" className="TransWebButton" onClick={() => { window.location.href = "https://www.seemymachines.com/cssdWorkflowManagement" } }>{config["website_button"][language]}</button>
                    <button type="button"className="TransBrochureButton" onClick={() => { window.location.href = config["SSDpc-brochure"][language]} }>{config["brochure_button"][language]}</button>
                </div>
            </div>
    </section>
    )
 }

 export { SeeMyMachinesPC }