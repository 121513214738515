import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HoverAccordion, PCHomeBanner  } from "../../components";
import { getLocalization } from "../../translations"
import './styles.css'

function PCHome({ language }){
 const navigate= useNavigate();
 const { search } = useLocation();

 useEffect(() => {
    if(search) {
        const offset = document.getElementById(search.split("=")[1])?.offsetTop - 50
        window.scrollTo(0, offset)
     }
  }, [search]);
 
 return(
    <div className="home-pc">
        <PCHomeBanner language={language} />
        <div className="our-product-heading-wrapper">
            <div className="our-product-heading-container align-center">
                <div className="product-main-title-pc">
                    <h3 className="product-into--title-pc">Our Products</h3>
                    <h4 className="product-into--title2-pc">{getLocalization(language, "product_title")}</h4>
                </div>
            </div>
        </div>
        <div className="our-product-container-wrapper">
            <div className="product-container-pc align-center">
                {/* Cross Channel */}
                <HoverAccordion 
                    id="cross-channel"
                    logo="/Logos/PC/Crosschannel.svg" 
                    title={getLocalization(language, "crosschannel_title")} 
                    laptopIcon="/productPage/laptop/cross-channel.svg"
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/cross-channel")}>
                        <p>{getLocalization(language, "crosschannel_description_1")}</p>
                        <p>{getLocalization(language, "crosschannel_description_1.1")}</p>   
                </HoverAccordion>

                {/* Evalgator */}
                 <HoverAccordion 
                    id="evalgator"
                    logo="/Logos/PC/Evalgator.svg" 
                    title={getLocalization(language, "evalgator_title")} 
                    laptopIcon="/productPage/laptop/evalgator.svg" 
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/evalgator")}>
                        <p>{getLocalization(language, "evalgator_description_1")}<br/>{getLocalization(language, "evalgator_description_1.1")}<br/>{getLocalization(language, "evalgator_description_1.2")}</p>    
                </HoverAccordion>

                {/* Affiliate */}
                <HoverAccordion 
                    id="affiliate"
                    logo="/Logos/PartnerFront.svg" 
                    title={getLocalization(language, "affiliate_title")} 
                    laptopIcon="/productPage/laptop/affiliate.svg" 
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/affiliate")}>
                        <p>{getLocalization(language, "pcaffiliate_description_1")}<br/>{getLocalization(language, "pcaffiliate_description_1.1")}</p>    
                </HoverAccordion>

                {/* Finbook */}
                <HoverAccordion 
                    id="finbooks"
                    logo="/Logos/PC/Finbooks.svg" 
                    title={getLocalization(language, "finbook_title")} 
                    laptopIcon="/productPage/laptop/finbooks.svg"
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/finbooks")}>
                        <p>{getLocalization(language, "pcfinbook_description_1")}<br/>{getLocalization(language, "pcfinbook_description_1.1")}<br/>{getLocalization(language, "pcfinbook_description_1.2")}</p>    
                </HoverAccordion>

                {/* SlashQ */}
                <HoverAccordion 
                    id="slashq"
                    logo="/Logos/PC/SlashQ.svg" 
                    title={getLocalization(language, "slashq_title")} 
                    laptopIcon="/productPage/laptop/slashq.svg" 
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/slashq")}>
                        <p>{getLocalization(language, "slashq_description_1")}<br/>{getLocalization(language, "slashq_description_1.1")}</p>
                </HoverAccordion>

                {/* See My Machines */}
                <HoverAccordion 
                    id="seeMyMachine"
                    logo="/Logos/PC/SeeMylogo.svg" 
                    title={getLocalization(language, "seemymachine_title")} 
                    laptopIcon="/productPage/laptop/seeMyMachine.svg"
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/see-my-machines")}>
                        <p>{getLocalization(language, "slashq_description_1")}<br/>{getLocalization(language, "slashq_description_1.1")}</p>    
                </HoverAccordion>

                {/* Enlite */}
                <HoverAccordion 
                    id="enlite"
                    logo="/Logos/PC/Enlite.svg" 
                    title={getLocalization(language, "enlite_title")} 
                    laptopIcon="/productPage/laptop/enlite.svg"
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/enlite")}>
                        <p>{getLocalization(language, "enlite_description_1")}<br/>{getLocalization(language, "enlite_description_1.1")}</p>    
                </HoverAccordion>

                {/* Rehash */}
                <HoverAccordion 
                    id="rehash"
                    logo="/Logos/PC/Rehash.svg" 
                    title={getLocalization(language, "rehash_title")} 
                    laptopIcon="/productPage/laptop/rehash.svg"
                    buttonText="View More" 
                    onButtonClick={()=>navigate("/products/rehash")}>
                        <p>{getLocalization(language, "rehash_description_1")}{getLocalization(language, "rehash_description_1.1")}{getLocalization(language, "rehash_description_1.2")}</p>    
                </HoverAccordion>
            </div>
        </div>
    </div>
 )   
}

export { PCHome } 