import React, {useState}from "react";
import joinClasses from "../../utils/joinClasses";
import './styles.css'
import {Button} from "../"

function AccordionMenu({logo,title, laptopIcon, buttonText, onButtonClick, children}){

    const [expanded, setExpanded] = useState(false);
    const [rotation, setRotation] = useState(0);

    
    
    const toggleExpanded = () => {
        setExpanded(!expanded)
        setRotation(rotation === 0 ? 180 : 0); // アイコンの回転角度を切り替える

    }

    return (
        <div >
            <p className="menu-title2" onClick={toggleExpanded}>Our Products
          
                <img 
                    src="/menu/arrowExpandMenu.svg" 
                    alt="Arrow Icon" 
                    className="arrowExpandMenu"
                    style={{ transform: `rotate(${rotation}deg)` }} 
                />
            </p>
            <p className="menu-subtitle2"> 製品一覧</p>  
            

            { expanded && (
                <div className="accordionMenuItems"> 
                    {children}
                </div>
            )}
            
        </div>
    )
}

export {AccordionMenu}
