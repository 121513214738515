import React from "react";
import './styles.css'

function Footer(){
    return (
        <footer className="footer"> 
            <p>
                Copyright © 2023 QBurst. All Rights Reserved.
            </p>

        </footer>
    )
}

export { Footer }
